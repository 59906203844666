<template>
  <div class="company-container">
    <header>
      <div class="menu-item" v-for="(item,i) in menu" :key="i" :class="isSelected[i]?'isSelected':''" @click="changePath(i)">{{item.name}}</div>
    </header>
    <main :class="isScroll?'scroll':''">
      <transition name="el-fade-in-linear">
        <router-view v-show="routerShow" :companyId="companyId" :isAdmin="isAdmin"></router-view>
      </transition>
    </main>
  </div>
</template>

<script>
export default {
  props: {
    companyId: {
      type: Number,
      default: 0
    },
    isAdmin: {
      type: Boolean,
      default: true
    },
    isUnit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      menu: [],
      isSelected: [true, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
      isScroll: false,
      routerShow: true
    }
  },
  created () {
    if (this.isAdmin) {
      this.menu = [
        { name: '基本概述 |', path: '/home/unit/info/base', isScroll: true },
        { name: '消防重点单位申请表 |', path: '/home/unit/info/apply', isScroll: true },
        { name: '消防重点单位备案登记表 |', path: '/home/unit/info/register', isScroll: true },
        { name: '实景照片 |', path: '/home/unit/info/photo', isScroll: true },
        { name: '企业证照信息 |', path: '/home/unit/info/licence', isScroll: true },
        { name: '平面布局及建筑图纸 |', path: '/home/unit/info/layout', isScroll: false },
        { name: '消防维护合同 |', path: '/home/unit/info/contract', isScroll: false },
        { name: '灭火和应急预案演练记录列表 |', path: '/home/unit/info/drill', isScroll: false },
        { name: '消防管理网络实名制人员 |', path: '/home/unit/info/realname', isScroll: false },
        { name: '消防重点单位身份证明材料 |', path: '/home/unit/info/certificate', isScroll: false },
        { name: '消防重点部位情况 |', path: '/home/unit/info/keyparts', isScroll: false },
        { name: '消防安全重点单位告知书 |', path: '/home/unit/info/notice', isScroll: true },
        { name: '消防安全责任人承诺书 |', path: '/home/unit/info/committed', isScroll: true },
        // { name: '消防设施、灭火器材状况 |', path: '' },
        { name: '消防安全管理制度 |', path: '/home/unit/info/system', isScroll: false },
        { name: '消防安全职责 |', path: '/home/unit/info/duty', isScroll: false }
      ]
    } else {
      if (this.isUnit) {
        this.menu = [
          { name: '基本概述 |', path: '/home/link/info/base', isScroll: true },
          { name: '消防重点单位申请表 |', path: '/home/link/info/apply', isScroll: true },
          { name: '消防重点单位备案登记表 |', path: '/home/link/info/register', isScroll: true },
          { name: '实景照片 |', path: '/home/link/info/photo', isScroll: true },
          { name: '企业证照信息 |', path: '/home/link/info/licence', isScroll: true },
          { name: '平面布局及建筑图纸 |', path: '/home/link/info/layout', isScroll: false },
          { name: '消防维护合同 |', path: '/home/link/info/contract', isScroll: false },
          { name: '灭火和应急预案演练记录列表 |', path: '/home/link/info/drill', isScroll: false },
          { name: '消防管理网络实名制人员 |', path: '/home/link/info/realname', isScroll: false },
          { name: '消防重点单位身份证明材料 |', path: '/home/link/info/certificate', isScroll: false },
          { name: '消防重点部位情况 |', path: '/home/link/info/keyparts', isScroll: false },
          { name: '消防安全重点单位告知书 |', path: '/home/link/info/notice', isScroll: true },
          { name: '消防安全责任人承诺书 |', path: '/home/link/info/committed', isScroll: true },
          // { name: '消防设施、灭火器材状况 |', path: '' },
          { name: '消防安全管理制度 |', path: '/home/link/info/system', isScroll: false },
          { name: '消防安全职责 |', path: '/home/link/info/duty', isScroll: false }
        ]
      } else {
        this.menu = [
          { name: '基本概述 |', path: '/home/companyinfo/info/base', isScroll: true },
          { name: '消防重点单位申请表 |', path: '/home/companyinfo/info/apply', isScroll: true },
          { name: '消防重点单位备案登记表 |', path: '/home/companyinfo/info/register', isScroll: true },
          { name: '实景照片 |', path: '/home/companyinfo/info/photo', isScroll: true },
          { name: '企业证照信息 |', path: '/home/companyinfo/info/licence', isScroll: true },
          { name: '平面布局及建筑图纸 |', path: '/home/companyinfo/info/layout', isScroll: false },
          { name: '消防维护合同 |', path: '/home/companyinfo/info/contract', isScroll: false },
          { name: '灭火和应急预案演练记录列表 |', path: '/home/companyinfo/info/drill', isScroll: false },
          { name: '消防管理网络实名制人员 |', path: '/home/companyinfo/info/realname', isScroll: false },
          { name: '消防重点单位身份证明材料 |', path: '/home/companyinfo/info/certificate', isScroll: false },
          { name: '消防重点部位情况 |', path: '/home/companyinfo/info/keyparts', isScroll: false },
          { name: '消防安全重点单位告知书 |', path: '/home/companyinfo/info/notice', isScroll: true },
          { name: '消防安全责任人承诺书 |', path: '/home/companyinfo/info/committed', isScroll: true },
          // { name: '消防设施、灭火器材状况 |', path: '' },
          { name: '消防安全管理制度 |', path: '/home/companyinfo/info/system', isScroll: false },
          { name: '消防安全职责 |', path: '/home/companyinfo/info/duty', isScroll: false }
        ]
      }
    }
    this.changePath(0)
  },
  methods: {
    changePath (index) {
      this.isSelected.forEach((item, i) => {
        index === i ? this.$set(this.isSelected, i, true) : this.$set(this.isSelected, i, false)
      })
      this.isScroll = this.menu[index].isScroll
      this.$router.push(this.menu[index].path)
      console.log(index)
    }
  }
}
</script>

<style lang="scss" scoped>
.company-container {
  width: 100%;
  height: 100%;
  padding: 10px 10px 0 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: rgba(30, 74, 115, 0.5);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  position: relative;
  header {
    flex: 1;
    background-color: rgba(7, 104, 159, 0.15);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    padding: 5px;
    box-sizing: border-box;
    margin-bottom: 10px;
    .menu-item {
      float: left;
      font-size: 13px;
      margin-right: 5px;
      vertical-align: middle;
      line-height: 20px;
      cursor: pointer;
      color: #fff;
    }
    .isSelected {
      color: #409eff;
    }
  }
  main {
    flex: 13;
  }
  .scroll {
    overflow-y: scroll;
  }
}
.company-container:after {
  position: absolute;
  top: -1px;
  left: -1px;
  content: "";
  border-right: 20px solid transparent;
  border-top: 20px solid #13355b;
}
</style>
